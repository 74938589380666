import React, { useCallback, useState } from "react"

import _debounce from "lodash.debounce"
import Filter from "./Filter"

const arrayToObject = (array, key) =>
  array.reduce((obj, item) => {
    obj[item[key]] = "0"
    return obj
  }, {})

const SearchAndFilter = ({ sendQuery, filters, taxPluralNames }) => {
  const [searchValue, setSearchValue] = useState("")

  // Somehow I need to create an object with taxonomy names as keys:
  const [taxonomyFilters, setTaxonomyFilters] = useState(
    arrayToObject(filters, "name")
  )

  const delayedQuery = useCallback(
    _debounce((search, filters) => sendQuery(search, filters), 500),
    []
  )

  const onChangeSearch = (e) => {
    setSearchValue(e.target.value)
    delayedQuery(e.target.value, taxonomyFilters)
  }

  const updateFilter = (name, value) => {
    const filterGroup = {
      ...taxonomyFilters,
      [name]: value,
    }
    setTaxonomyFilters(filterGroup)
    sendQuery(searchValue, filterGroup)
  }

  const formInputCount = 1 + filters.length
  const maxColCount = formInputCount > 4 ? 4 : formInputCount
  const colDivider = 12 / maxColCount

  return (
    <div className="row mb-5">
      <div className={`col-lg-${colDivider} mb-3`}>
        <div role="search" className="search-form">
          <label>
            <span className="screen-reader-text">Search for:</span>
            <input
              type="search"
              className="search-field"
              placeholder="Search …"
              name="s"
              onChange={onChangeSearch}
              value={searchValue}
            />
          </label>
        </div>
      </div>
      {filters &&
        filters.map(({ name, items }, index) => {
          return (
            <div key={name} className={`col-lg-${colDivider} mb-3`}>
              <div className="select-wrapper">
                <div id="cat_filter">
                  <Filter
                    updateFilter={updateFilter}
                    terms={items}
                    taxName={name}
                    taxPluralName={taxPluralNames?.[index]}
                  />
                </div>
              </div>
            </div>
          )
        })}
    </div>
  )
}

export default SearchAndFilter
