import React, { useState } from "react"
import { graphql } from "gatsby"
import { gql, GraphQLClient } from "graphql-request"
import Layout from "../../components/Layout"

import ArchivePagination from "../../components/ArchivePagination"
import ProjectPreview from "../../components/ProjectPreview"
import SearchAndFilter from "../../components/SearchAndFilter"

const ArchiveProject = (props) => {
  const {
    data: {
      allWpProject: { nodes, pageInfo },
      allWpTermNode,
    },
    pageContext: {
      archivePath,
      taxPluralNames,
      siteMetadata: { wpgraphqlUrl },
    },
  } = props

  const [projectResult, setProjectsResult] = useState()

  const getProjects = async (searchValue, taxonomyFilter) => {
    const mutation = gql`
      query GET_PROJECTS(
        $search: String
        $taxArray: [RootQueryToProjectConnectionWhereArgsTaxArray]
      ) {
        projects(
          where: {
            search: $search
            taxQuery: { relation: AND, taxArray: $taxArray }
          }
        ) {
          nodes {
            uri
            title
            databaseId
            date
            featuredImage {
              node {
                sourceUrl
                altText
              }
            }
          }
        }
      }
    `

    const graphQLClient = new GraphQLClient(wpgraphqlUrl, {})

    const taxArray = Object.entries(taxonomyFilter).reduce(
      (result, [key, value]) => {
        if (value !== "0") {
          result.push({
            terms: value,
            taxonomy: key.toUpperCase(),
            operator: "IN",
            field: "TAXONOMY_ID",
          })
        }
        return result
      },
      []
    )

    if (!searchValue && taxArray?.length === 0) {
      setProjectsResult(null)
      return
    }

    const projectData = await graphQLClient.request(mutation, {
      search: searchValue,
      taxArray: !taxonomyFilter && taxArray?.length === 0 ? null : taxArray,
    })

    setProjectsResult(projectData)
  }

  return (
    <Layout bodyClass="archive post-type-archive post-type-archive-fc_projects app-data index-data archive-data archive-fc_projects-data">
      <section id="projects-header" className="dark-bg">
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>Our Work.</h1>
              <p>
                We're excited to help the businesses we work with and the people
                that make them a reality. Take a look at some of our recent
                launches and envision what we can do for you and your business!
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mb-5 pb-0">
        <div className="container">
          <SearchAndFilter
            sendQuery={getProjects}
            filters={allWpTermNode.group}
            taxPluralNames={taxPluralNames}
          />
        </div>
      </section>

      <section className="mt-0 pt-0">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div id="loopContainer">
                <div className="row">
                  {projectResult?.projects?.nodes &&
                    projectResult.projects.nodes.map((project, index) => {
                      return (
                        <ProjectPreview
                          key={index}
                          project={project}
                          dynamicImage
                          isLast={index === nodes.length - 1}
                        />
                      )
                    })}
                  {!projectResult?.projects &&
                    nodes &&
                    nodes.map((project) => {
                      return (
                        <ProjectPreview
                          key={project.databaseId}
                          project={project}
                        />
                      )
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {!projectResult?.projects && nodes && (
        <ArchivePagination {...pageInfo} archivePath={archivePath} />
      )}
    </Layout>
  )
}

export const query = graphql`
  query ArchiveProjectPage(
    $offset: Int!
    $perPage: Int!
    $termTaxonomyId: Int
    $taxSingleNames: [String]
  ) {
    allWpProject(
      limit: $perPage
      skip: $offset
      sort: { fields: date, order: DESC }
      filter: {
        terms: {
          nodes: { elemMatch: { termTaxonomyId: { eq: $termTaxonomyId } } }
        }
      }
    ) {
      nodes {
        ...ProjectPreviewContent
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        currentPage
        pageCount
      }
    }
    allWpTermNode(filter: { nodeType: { in: $taxSingleNames } }) {
      group(field: nodeType) {
        name: fieldValue
        items: nodes {
          databaseId
          name
          count
        }
      }
    }
  }
`

export default ArchiveProject
