import React from "react"
import { Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"

const ProjectPreview = ({ project, dynamicImage }) => {
  return (
    <div className="col-md-6 mb-5">
      <Link className="project" to={project.uri} title={project.title}>
        {(dynamicImage && project.featuredImage) ? (
            <div style={{backgroundImage:`url(${project.featuredImage.node.sourceUrl})`}}>
              <div className="overlay d-flex align-items-center justify-content-center">
                <div className="text-center">
                  <h2 className="text-uppercase" dangerouslySetInnerHTML={{ __html: project.title }}/>
                  <h4 className="h4">View Project</h4>
                </div>
              </div>
            </div>
        ) : (
          <BackgroundImage
            preserveStackingContext
            fluid={project.featuredImage?.node.remoteFile?.childImageSharp?.fluid}
          >
            <div className="overlay d-flex align-items-center justify-content-center">
              <div className="text-center">
                <h2
                  className="text-uppercase"
                  dangerouslySetInnerHTML={{ __html: project.title }}
                />
                <h4 className="h4">View Project</h4>
              </div>
            </div>
          </BackgroundImage>
        )}

      </Link>
    </div>
  )
}

export default ProjectPreview
