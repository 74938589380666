import React from "react"
import { Link } from "gatsby"
import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const renderPreviousLink = ({ hasPreviousPage, currentPage, archivePath }) => {
  let previousLink = null

  if (currentPage === 2) {
    previousLink = archivePath
  } else if (currentPage > 2) {
    previousLink = `${archivePath}page/${currentPage - 1}`
  }

  if (hasPreviousPage) {
    return (
      <Link className="prev-page" to={previousLink}>
        <FontAwesomeIcon icon={faChevronLeft} /> Newer
      </Link>
    )
  }
  return (
    <span className="prev-page page-numbers placeholder" aria-hidden="true">
      <FontAwesomeIcon icon={faChevronLeft} /> Newer
    </span>
  )
}

const renderNextLink = ({ hasNextPage, currentPage, archivePath }) => {
  if (hasNextPage) {
    return (
      <Link className="next-page" to={`${archivePath}page/${currentPage + 1}`}>
        Older <FontAwesomeIcon icon={faChevronRight} />
      </Link>
    )
  }
  return (
    <span className="next-page page-numbers placeholder" aria-hidden="true">
      Older <FontAwesomeIcon icon={faChevronRight} />
    </span>
  )
}

const renderPagesInBetween = ({ currentPage, pageCount, archivePath }) => {
  const pageNumber = (page, isCurrent, isFirst) => {
    if (isCurrent) {
      return {
        tag: "span",
        children: page,
        className: "num-page",
      }
    }

    const to = isFirst ? archivePath : `${archivePath}page/${page}`

    return {
      tag: Link,
      children: page,
      className: "num-page link",
      to,
    }
  }

  const dots = {
    tag: "span",
    children: "…",
    className: "num-page dots",
  }

  const elementsToRender = []

  if (pageCount > 5) {
    // current is 1 - 3: show all on left side and dots for right
    if (currentPage < 4) {
      for (let i = 1; i <= currentPage + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
      }
      elementsToRender.push(dots) // dots in between
      elementsToRender.push(pageNumber(pageCount, false, false)) // last page
    }
    // if on of the last 3
    else if (currentPage >= pageCount - 2) {
      elementsToRender.push(pageNumber(1, false, true)) // last page
      elementsToRender.push(dots)

      for (let i = currentPage - 1; i < pageCount + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
      }
    } else {
      elementsToRender.push(pageNumber(1, false, true)) // last page
      elementsToRender.push(dots)

      for (let i = currentPage - 1; i <= currentPage + 1; i++) {
        elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
      }

      elementsToRender.push(dots)
      elementsToRender.push(pageNumber(pageCount, false, false)) // last page
    }
  } else {
    for (let i = 1; i < pageCount + 1; i++) {
      elementsToRender.push(pageNumber(i, currentPage === i, i === 1))
    }
  }

  return elementsToRender.map(({ tag: Tag, children, ...props }, index) => (
    <Tag key={index} {...props}>
      {children}
    </Tag>
  ))
}

const ArchivePagination = ({
  hasPreviousPage,
  hasNextPage,
  currentPage,
  pageCount,
  archivePath,
}) => {
  if (pageCount <= 1) {
    return null
  }

  return (
    <nav className="blog-pagination" role="navigation" aria-label="Posts">
      <h2 className="screen-reader-text">Posts navigation</h2>
      {renderPreviousLink({ hasPreviousPage, currentPage, archivePath })}
      {renderPagesInBetween({ currentPage, pageCount, archivePath })}
      {renderNextLink({ hasNextPage, currentPage, archivePath })}
    </nav>
  )
}

export default ArchivePagination
