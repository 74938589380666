import React, { useState } from "react"

const Filter = ({ updateFilter, terms, taxName, taxPluralName }) => {
  const [termValue, setTermValue] = useState("0")

  const onChangeFilterTerm = (e) => {
    // value is a string value coming from the select options
    setTermValue(e.target.value)
    updateFilter(taxName, e.target.value)
  }

  return (
    <select
      id="cat"
      className="postform"
      onChange={onChangeFilterTerm}
      value={termValue}
    >
      <option value={0}>All {taxPluralName}</option>
      {terms &&
        terms.map((term) => {
          if (!term.count) {
            return null
          }
          return (
            // eslint-disable-next-line jsx-a11y/control-has-associated-label
            <option
              key={term.databaseId}
              className="level-0"
              value={term.databaseId}
              dangerouslySetInnerHTML={{ __html: term.name }}
            />
          )
        })}
    </select>
  )
}

export default Filter
